/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { setSectionScrollIndex } from "../../redux/callbacksState/callbacksState.actions";
import { select_sectionScroll_index } from "../../redux/callbacksState/callbacksState.selectors";
import { getTextWidth } from "../../tools/getTextWidth";
import "./trails-text.scss";

interface Props {
  text: string;
  sectionIndex: number;
  addictionalDelay?: number;
}

const TrailsText: React.FC<Props> = (props: Props) => {
  const ref = useRef<any>();
  const [trails, setTrails] = useState([] as Array<string>);
  const [isOpen, setisOpen] = useState(false);
  const dispatch = useDispatch();
  const [text, setText] = useState("")
  const sectionScrollIndex = useSelector(select_sectionScroll_index, shallowEqual)
  const addictionalDelay = 0.2 * (props.addictionalDelay ? props.addictionalDelay : 0);
  // const size = useWindowSize();
  // let globalIntervalTrailsInterval: NodeJS.Timeout | undefined = undefined;
  // let globalIntervalTrailsText: string | undefined = undefined;
  let interval: NodeJS.Timeout | undefined;
  const setTrailsBlocks = () => {

    var el = ref.current! as any;
    if (props.sectionIndex === 2) {
      // console.log("now");
    }
    if (el !== null) {

      var styleWindow = window;
      var maxWidth = Number(styleWindow.getComputedStyle(el, null).getPropertyValue('width').slice(0, -2));
      var fontSize = styleWindow.getComputedStyle(el, null).getPropertyValue('font-size');
      var fontFamily = styleWindow.getComputedStyle(el, null).getPropertyValue('font-family');
      var fontWeight = styleWindow.getComputedStyle(el, null).getPropertyValue('font-weight');
      var fullFont = `${fontWeight} ${fontSize} ${fontFamily}`;

      // var letterSpacing = styleWindow.getComputedStyle(el, null).getPropertyValue('letter-spacing'); DOESNT WORK

      var textReplace = text;
      var lettersToReplace = ["a", "i", "o", "u", "w", "z", "A", "I", "O", "U", "W", "Z", "&"];
      var arrayLength = lettersToReplace.length;
      for (var i = 0; i < arrayLength; i++) {
        var textSplit = textReplace.split(' ' + lettersToReplace[i] + ' ');
        textReplace = textSplit.join(' ' + lettersToReplace[i] + '|');

        // textSplit = textReplace.split(' ' + lettersToReplace[i]);
        // textReplace = textSplit.join('~' + lettersToReplace[i]);
      }

      var arrayOfWords = textReplace.split(" ");
      var tempText = arrayOfWords[0];
      var tempTrails = [] as Array<string>;

      for (let i = 1; i < arrayOfWords.length; i++) {
        if (getTextWidth(tempText + " " + arrayOfWords[i], fullFont) >= maxWidth) {
          tempTrails = [...tempTrails, tempText]
          tempText = arrayOfWords[i];
        } else {
          tempText = tempText + " " + arrayOfWords[i];
        }
      }
      if (tempText.length > 0) {
        tempTrails = [...tempTrails, tempText]
      }

      setTrails(tempTrails);
      setisOpen(true);
    }
  }

  useEffect(() => {
    setisOpen(false);
    dispatch(setSectionScrollIndex(-1))

    return () => {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setisOpen(false);

    setText(props.text)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.text])

  useEffect(() => {

  }, [sectionScrollIndex])

  useEffect(() => {
    if (interval !== undefined) clearInterval(interval);
    setisOpen(false);

    setTrailsBlocks();
    interval = setInterval(() => {
      setTrailsBlocks();
    }, 100);

    return () => {
      if (interval !== undefined) clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text])


  return (
    <div ref={ref} className="trailsWrapper">
      {trails ? trails.map((trail: string, index: number) => {
        return (
          <span key={index} className={`tailWrapper ${isOpen && sectionScrollIndex >= props.sectionIndex ? "isOpen" : ""}`} style={{ transitionDelay: (isOpen && sectionScrollIndex >= props.sectionIndex ? (addictionalDelay + (0.2 * index) + 0.2) : 0) + 's' }}>
            <span key={index} className={`tail`} style={{ transitionDelay: (addictionalDelay + (0.2 * index)) + 's' }}>{" " + trail.replace("|", "\u00A0").replace("|", "\u00A0") + " "}</span>
            {/* <span key={index} className={`tail`} style={{ transitionDelay: (addictionalDelay + (0.2 * index)) + 's' }}>{" " + trail + " "}</span> */}
          </span>
        )
      }) : null}
    </div>
  )
}

export default TrailsText;
