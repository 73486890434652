import React, { useEffect, useRef, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { select_sectionScroll_index } from "../../redux/callbacksState/callbacksState.selectors";
import "./trails-text.scss";

interface Props {
  text: string;
  sectionIndex: number;
  addictionalDelay?: number;
}

const SimpleTrailText: React.FC<Props> = (props: Props) => {
  const ref = useRef<any>();
  const [isOpen, setisOpen] = useState(false);
  const sectionScrollIndex = useSelector(select_sectionScroll_index, shallowEqual)
  const addictionalDelay = 0.2 * (props.addictionalDelay ? props.addictionalDelay : 0);

  const parser = new DOMParser();

  useEffect(() => {
    setTimeout(() => {
      setisOpen(true);
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div ref={ref}>
      <span className={`tailWrapper ${isOpen && sectionScrollIndex >= props.sectionIndex ? "isOpen" : ""}`} style={{ transitionDelay: (addictionalDelay + (0.2 * 0) + 0.2) + 's' }}>
        <span className={`tail`} style={{ transitionDelay: (addictionalDelay + (0.2 * 0)) + 's' }}>
          {parser.parseFromString(props.text, 'text/html').body.textContent}
        </span>
      </span>
    </div>
  )
}

export default SimpleTrailText;

