import React from "react";
import Container from "../../../../components/container/container";
import DetectableSection from "../../../../components/detectable-section/detectable-section";
import Col from "../../../../components/grid/col";
import Grid from "../../../../components/grid/grid";
import Row from "../../../../components/grid/row";
import StandardBlock from "../../../../components/standard-block/standard-block";
import TrailsText from "../../../../components/trails-text/trails-text";
import useLang from "../../../../tools/useLang";
import "./about-section.scss";

interface Props {
  index: number;
}

const AboutSection: React.FC<Props> = (props: Props) => {

  return (
    <DetectableSection index={props.index} isNotHideable={true} className="about_section">
      <Container>
        <Grid>
          <Row>
            <Col size={[12]}>
              <StandardBlock>
                <div className="title">
                  <TrailsText
                    text={useLang("I’m a freelance visual systems & brand identity designer based in Wrocław, Poland.", "Jestem niezależnym projektantem systemów identyfikacji wizualnej z Wrocławia.")}
                    sectionIndex={props.index}
                  />
                </div>
              </StandardBlock>
            </Col>
          </Row>
        </Grid>
      </Container>
    </DetectableSection>
  )
}

export default AboutSection;