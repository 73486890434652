import React from "react";
import HeroSection, { HeroInfoColDto } from "./sections/hero-section/hero-section";
import useLang, { useLangAny } from "../../tools/useLang";
import DescriptionSection from "./sections/description-section/description-section";

import ImageSection from "./sections/image-section/image-section";

const imagesFirst = [
  "/images/portfolio/hala/Hala_Stulecia_Identyfikacja_wizualna_0.jpg",
  "/images/portfolio/hala/Hala_Stulecia_Identyfikacja_wizualna_1.jpg",
  "/images/portfolio/hala/Hala_Stulecia_Identyfikacja_wizualna_2.jpg",
  "/images/portfolio/hala/Hala_Stulecia_Identyfikacja_wizualna_3.jpg"
]

const imagesSecond = [
  "/images/portfolio/hala/Hala_Stulecia_Identyfikacja_wizualna_4.jpg",
  "/images/portfolio/hala/Hala_Stulecia_Identyfikacja_wizualna_5.jpg",
  "/images/portfolio/hala/Hala_Stulecia_Identyfikacja_wizualna_6.jpg",
  "/images/portfolio/hala/Hala_Stulecia_Identyfikacja_wizualna_7.jpg",
  "/images/portfolio/hala/Hala_Stulecia_Identyfikacja_wizualna_8.jpg",
  "/images/portfolio/hala/Hala_Stulecia_Identyfikacja_wizualna_9.jpg",
  "/images/portfolio/hala/Hala_Stulecia_Identyfikacja_wizualna_10.jpg",
  "/images/portfolio/hala/Hala_Stulecia_Identyfikacja_wizualna_11.jpg",
  "/images/portfolio/hala/Hala_Stulecia_Identyfikacja_wizualna_12.jpg",
  "/images/portfolio/hala/Hala_Stulecia_Identyfikacja_wizualna_13.jpg",
  "/images/portfolio/hala/Hala_Stulecia_Identyfikacja_wizualna_14.jpg",
  "/images/portfolio/hala/Hala_Stulecia_Identyfikacja_wizualna_15.jpg",
  "/images/portfolio/hala/Hala_Stulecia_Identyfikacja_wizualna_16.jpg",
  "/images/portfolio/hala/Hala_Stulecia_Identyfikacja_wizualna_17.jpg",
]

interface Props {
  pageIndex: number;
}

const CentennialHallPage: React.FC<Props> = (props: Props) => {

  return (
    <>
      <HeroSection
        pageIndex={props.pageIndex}
        sectionIndex={1}
        title={useLang("Visual identity system for one of Poland's most recognizable modernist architectural icon.", "System identyfikacji wizualnej dla architektonicznej ikony modernizmu.")}
        infoCols={[
          {
            header: useLang("Areas", "Zakres"),
            items: useLangAny(["Visual Identity", "UX & UI", "Brand Guidelines", "Communication Design"], ["Język wizualny", "UX & UI", "Księga SIW", "Język komunikacji"])
          },
          {
            header: useLang("Role", "Rola"),
            items: useLangAny(["Creative designer", "Art Director"], ["Główny projektant", "Nadzór artystyczny"])
          },
          {
            header: useLang("For", "Dla"),
            items: useLangAny([<a target="_blank" rel="noopener noreferrer" href="http://cietyjezyk.pl/">Cięty Język Agency</a>], [<a target="_blank" rel="noopener noreferrer" href="http://cietyjezyk.pl/">Agencja Cięty Język</a>])
          },
          {
            header: useLang("Year", "Rok"),
            items: ["2018-2020"]
          }
        ] as Array<HeroInfoColDto>}
      />

      {imagesFirst.map((imageUrl: string, index: number) => {
        return (
          <ImageSection pageIndex={props.pageIndex} sectionIndex={index + 2} size={9}>
            <img src={imageUrl} alt="" />
          </ImageSection>
        );
      })}

      <DescriptionSection
        pageIndex={props.pageIndex}
        sectionIndex={6}
        description={useLang("The biggest challenge in designing this system was making it easy to use for every employee of the Centennial Hall administration. It worked, although there were a few mishaps.", "Największym wyzwaniem przy projektowaniu tego systemu, było sprawienie by był prosty w obsłudze dla każdego pracownika administracji Hali Stulecia. Udało się, chociaż było parę wpadek.")}
      />

      {imagesSecond.map((imageUrl: string, index: number) => {
        return (
          <ImageSection pageIndex={props.pageIndex} sectionIndex={index + 8} size={9}>
            <img src={imageUrl} alt="" />
          </ImageSection>
        );
      })}
    </>
  )
}

export default CentennialHallPage;