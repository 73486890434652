import React from "react";
import HeroSection, { HeroInfoColDto } from "./sections/hero-section/hero-section";
import useLang, { useLangAny } from "../../tools/useLang";

import ImageSection from "./sections/image-section/image-section";

const images = [
  "/images/portfolio/irmatiq/Irmatiq_identyfikacja_wizualna_1.jpg",
  "/images/portfolio/irmatiq/Irmatiq_identyfikacja_wizualna_2.jpg",
  "/images/portfolio/irmatiq/Irmatiq_identyfikacja_wizualna_3.jpg",
  "/images/portfolio/irmatiq/Irmatiq_identyfikacja_wizualna_4.jpg",
  "/images/portfolio/irmatiq/Irmatiq_identyfikacja_wizualna_5.jpg",
  "/images/portfolio/irmatiq/Irmatiq_identyfikacja_wizualna_6.jpg",
  "/images/portfolio/irmatiq/Irmatiq_identyfikacja_wizualna_7.jpg",
  "/images/portfolio/irmatiq/Irmatiq_identyfikacja_wizualna_8.jpg",
  "/images/portfolio/irmatiq/Irmatiq_identyfikacja_wizualna_9.jpg",
  "/images/portfolio/irmatiq/Irmatiq_identyfikacja_wizualna_10.jpg"
]

interface Props {
  pageIndex: number;
}

const Irmatiq: React.FC<Props> = (props: Props) => {
  return (
    <>
      <HeroSection
        pageIndex={props.pageIndex}
        sectionIndex={1}
        title={useLang("Visual identity for an investor relations management application.", "Identyfikacja wizualna dla aplikacji do zarządzania relacjami inwestorskimi.")}
        infoCols={
          [
            {
              header: useLang("Areas", "Zakres"),
              items: useLangAny(["Visual system", "Web design"], ["System wizualny", "Strona internetowa"]),
            },
            {
              header: useLang("Role", "Rola"),
              items: useLangAny(["Creative designer"], ["Główny projektant"]),
            },
            {
              header: useLang("For", "Dla"),
              items: ["Jakub Kunicki", "Marcel Rowiński", "Wojciech Zajączkowski"],
            },
            {
              header: useLang("Year", "Rok"),
              items: ["2022"],
            },
          ] as Array<HeroInfoColDto>
        }
      />

      {images.map((imageUrl: string, index: number) => {
        return (
          <ImageSection pageIndex={props.pageIndex} sectionIndex={index + 2} size={9}>
            <img src={imageUrl} alt="" />
          </ImageSection>
        );
      })}
    </>
  );
};

export default Irmatiq;
