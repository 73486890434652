import React, { ReactNode, useRef } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { select_scrollTopHandler_value } from "../../redux/callbacksState/callbacksState.selectors";
import useWindowScroll from "../../tools/useWindowScroll";
import "./detectable-section.scss";

interface Props {
  className?: string;
  children: ReactNode;
  index: number;
  isNotHideable?: boolean
}



const DetectableSection: React.FC<Props> = (props: Props) => {
  const { children, className, index, isNotHideable } = props;
  const scrollTopHandler_value = useSelector(select_scrollTopHandler_value, shallowEqual);

  const ref = useRef<any>();
  useWindowScroll(index, ref);

  return (
    <section className={`${className ? "detectable-section  " + className : "detectable-section"} ${scrollTopHandler_value === 1 && (isNotHideable !== true) ? "hide" : "show"}`} ref={ref}>
      {children}
    </section>
  )
}

export default DetectableSection;

