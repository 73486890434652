import React from "react";
import "./grid.scss";

interface Props {
  children: React.ReactNode,
  gap?: 64 | 32 | 0,
  className?: string,
}

const Row: React.FC<Props> = (props: Props) => {
  const { children, gap, className } = props;

  return (
    <div className={`row row-gap-${gap}${className ? " " + className : ""}`}>
      {children}
    </div>
  )
}

export default Row;