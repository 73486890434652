/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useRef } from "react";
import "./fluent-follow-block.scss";
import useWindowSize from '../../tools/useWindowSize';

interface Props {
  children: React.ReactNode;
}

export const moveConfig = {
  ease: 0.07,
  previousX: 0,
  previousY: 0,
  roundedX: 0,
  roundedY: 0,
}

const FluentFollowBlock: React.FC<Props> = (props: Props) => {
  const fluentFollowBlock = useRef<any>();

  const size = useWindowSize();

  const mousemoveEvent = (e: MouseEvent) => {
    (window as any).mouseX = e.clientX ? e.clientX : 0;
    (window as any).mouseY = e.clientY ? e.clientY : 0;
  }

  useEffect(() => {
    window.addEventListener('mousemove', mousemoveEvent);
    setTimeout(() => {
      requestAnimationFrame(() => followMouse());
    }, 100);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const followMouse = () => {

    moveConfig.previousX += ((window as any).mouseX - moveConfig.previousX) * moveConfig.ease
    moveConfig.previousY += ((window as any).mouseY - moveConfig.previousY) * moveConfig.ease;

    moveConfig.roundedX = Math.round(moveConfig.previousX * 100) / 100;
    moveConfig.roundedY = Math.round(moveConfig.previousY * 100) / 100;

    if (Number.isNaN(moveConfig.roundedX) || Number.isNaN(moveConfig.roundedY)) {
      moveConfig.previousX = 0;
      moveConfig.previousY =  0;
      moveConfig.roundedX =  0;
      moveConfig.roundedY =  0;
    }

    const difference = (window as any).mouseX - moveConfig.roundedX;
    const acceleration = difference / size.width;
    const velocity = +acceleration;
    const skew = velocity * 10;

    fluentFollowBlock.current.style.transform = `translate(-50%, -50%) skewY(${skew}deg)`;

    fluentFollowBlock.current.style.left = `${moveConfig.roundedX}px`;
    fluentFollowBlock.current.style.top = `${moveConfig.roundedY}px`;

    requestAnimationFrame(() => followMouse());
  }

  return (
    <div className={'fluent-follow-block'} ref={fluentFollowBlock}>
      {props.children}
    </div>
  )


}

export default FluentFollowBlock;