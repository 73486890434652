import React, { useEffect, useState } from "react";
import "./portfolio-section.scss";
import { shallowEqual, useSelector } from "react-redux";
import { select_currecntPortfolioBlockActive, select_isPortfolioActive } from "../../../../redux/callbacksState/callbacksState.selectors";

const PortfolioImg: React.FC<any> = () => {
  const image = useSelector(select_currecntPortfolioBlockActive, shallowEqual);
  const isActive = useSelector(select_isPortfolioActive, shallowEqual);
  const [prevImg, setprevImg] = useState("");
  const [currentImg, setCurrentImg] = useState("");
  const [swapTime, setSwapTime] = useState(false);

  const swap = () => {
    setCurrentImg(image);
    setSwapTime(true);
    setTimeout(() => {
      setSwapTime(false);
      setprevImg(image)
    }, 300);
  }

  useEffect(() => {
    swap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image])

  return (
    <div className={`portfolio-img-wrapper ${isActive ? "isActive" : ""}`}>
      <div className="portfolio-img"
        style={{
          backgroundImage: `url('${currentImg}')`
        }}>
        <div className={`portfolio-prev-img ${swapTime ? "swapTime" : ""}`}
          style={{
            backgroundImage: `url('${prevImg}')`
          }}></div>
      </div>
    </div>
  )
}

export default PortfolioImg;