import React from "react";
import "./portoflio-page.scss";
import Helmet from "react-helmet";
import PageWrapper from "../../components/page-wrapper/page-wrapper";
import NextProjectSection from "./sections/nextProject-section/nextProject-section";
import { PortfolioData } from "./_data";
import { useLocation } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { select_lang } from "../../redux/callbacksState/callbacksState.selectors";

interface Props {
  pageIndex: number;
}

const PortfolioLayout: React.FC<Props> = (props: Props) => {
  const location = useLocation();
  const lang = useSelector(select_lang, shallowEqual);
  const item = PortfolioData.find((x) => x.url === location.pathname);
  const nextItem = item ? PortfolioData.find((x) => x.order === (item?.order % PortfolioData.length) + 1) : null;

  return (
    <>
      {item ? (
        <PageWrapper className="portoflio-page" key={props.pageIndex}>
          <Helmet>
            <title>{"SPNM"}</title>
          </Helmet>
          {item?.page({ pageIndex: props.pageIndex })}
          {nextItem ? <NextProjectSection pageIndex={props.pageIndex} sectionIndex={99} img={nextItem.cover} url={nextItem?.url} /> : null}
        </PageWrapper>
      ) : (
        <></>
      )}
    </>
  );
};

export default PortfolioLayout;
