export class RoutePath {
  [key: string]: any;
  static RULES: string = '/warunki-uzytkowania';
  static PORTFOLIO_CENTENNIALHALL: string = '/centennial-hall';
  static PORTFOLIO_UMIHOTEL: string = '/umi-hotel';
  static PORTFOLIO_IRMATIQ: string = '/irmatiq';
  static PORTFOLIO_PELLEAPORTER: string = '/pelle-a-porter';
  static PORTFOLIO_NOMADHOUSE: string = '/nomad-house';
  static PORTFOLIO_BIBI: string = '/bibi-nail';
  static PORTFOLIO_PRIV_LAB: string = '/private-labels';
  static PORTFOLIO_LDAF: string = '/ldaf';
  static PORTFOLIO_RAPALATRAVELCONCIERGE: string = '/rapala-travel-concierge';
  static PORTFOLIO_COFFEEFLOWERS: string = '/coffee-flowers';
  static PORTFOLIO_SMOOF: string = '/smoof';
  static PORTFOLIO_LOGOFOLIO: string = '/logofolio';
  static HOME: string = '/';
}