import React, { useEffect } from "react";
import Container from "../../../../components/container/container";
import Grid from "../../../../components/grid/grid";
import "./nextProject-section.scss";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setCurrecntPortfolioBlockActive, setIsPortfolioActive } from "../../../../redux/callbacksState/callbacksState.actions";
import DetectableSection from "../../../../components/detectable-section/detectable-section";
import { select_sectionScroll_index } from "../../../../redux/callbacksState/callbacksState.selectors";
import TopLink from "../../../../components/top-link/top-link";
import { useLangAny } from "../../../../tools/useLang";

interface Props {
  sectionIndex: number;
  pageIndex: number;
  img: string;
  url: string;
}

const NextProjectSection: React.FC<Props> = (props: Props) => {
  const sectionScrollIndex = useSelector(select_sectionScroll_index, shallowEqual)

  const dispatch = useDispatch();

  const onMouseOver = () => {
    dispatch(setIsPortfolioActive(true));
    dispatch(setCurrecntPortfolioBlockActive(props.img));
  }

  const onMouseOut = () => {
    dispatch(setIsPortfolioActive(false));
  }

  useEffect(() => {
    dispatch(setIsPortfolioActive(false));

    return () => {
      dispatch(setIsPortfolioActive(false));
      dispatch(setCurrecntPortfolioBlockActive(""));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <DetectableSection index={(props.pageIndex * 1000) + props.sectionIndex} className="nextProject_section">
      <Container>
        <Grid>
          <div className={`nextProject-wrapper ${sectionScrollIndex >= ((props.pageIndex * 1000) + props.sectionIndex) ? "isOpen" : ""}`} onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
            <TopLink to={props.url}>
              {useLangAny(<>Next project&nbsp;{'>'}</>, <>Następny&nbsp;{'>'} </>)}
            </TopLink>
          </div>
        </Grid>
      </Container>
    </DetectableSection>
  )
}

export default NextProjectSection;