import React from "react";
import HeroSection, { HeroInfoColDto } from "./sections/hero-section/hero-section";
import useLang, { useLangAny } from "../../tools/useLang";
import ImageSection from "./sections/image-section/image-section";

const images = [
  "/images/portfolio/ldaf/La_Dame_Aux_Fleurs_identyfikacja_wizualna_1.jpg",
  "/images/portfolio/ldaf/La_Dame_Aux_Fleurs_identyfikacja_wizualna_2.jpg",
  "/images/portfolio/ldaf/La_Dame_Aux_Fleurs_identyfikacja_wizualna_3.jpg",
  "/images/portfolio/ldaf/La_Dame_Aux_Fleurs_identyfikacja_wizualna_4.jpg",
  "/images/portfolio/ldaf/La_Dame_Aux_Fleurs_identyfikacja_wizualna_5.jpg",
  "/images/portfolio/ldaf/La_Dame_Aux_Fleurs_identyfikacja_wizualna_6.jpg",
  "/images/portfolio/ldaf/La_Dame_Aux_Fleurs_identyfikacja_wizualna_7.jpg",
  "/images/portfolio/ldaf/La_Dame_Aux_Fleurs_identyfikacja_wizualna_8.jpg",
  "/images/portfolio/ldaf/La_Dame_Aux_Fleurs_identyfikacja_wizualna_9.jpg",
]
interface Props {
  pageIndex: number;
}



const LdafPage: React.FC<Props> = (props: Props) => {
  return (
    <>
      <HeroSection
        pageIndex={props.pageIndex}
        sectionIndex={1}
        title={useLang("Visual identity for a fashion consultant and showroom.", "Identyfikacja wizualna dla konsultantki modowej i showroomu.")}
        infoCols={
          [
            {
              header: useLang("Areas", "Zakres"),
              items: useLangAny(["Visual system"], ["System wizualny"]),
            },
            {
              header: useLang("Role", "Rola"),
              items: useLangAny(["Creative designer"], ["Główny projektant"]),
            },
            {
              header: useLang("For", "Dla"),
              items: useLangAny(["A friend"], ["Kolegi"])
            },
            {
              header: useLang("Year", "Rok"),
              items: ["2021"],
            },
          ] as Array<HeroInfoColDto>
        }
      />

      {images.map((imageUrl: string, index: number) => {
        return (
          <ImageSection pageIndex={props.pageIndex} sectionIndex={index + 2} size={9}>
            <img src={imageUrl} alt="" />
          </ImageSection>
        );
      })}
    </>
  );
};

export default LdafPage;
