import { useSelector, shallowEqual } from "react-redux";
import { select_lang } from "../redux/callbacksState/callbacksState.selectors";


export function useLangAny(en: any, pl: any) {
  const lang = useSelector(select_lang, shallowEqual);

  return (
    lang === "en" ? en : (lang === "pl" ? pl : en)
  )
}

export default function useLang(en: string, pl: string) {
  const lang = useSelector(select_lang, shallowEqual);

  return (
    lang === "en" ? en : (lang === "pl" ? pl : en)
  )
}