import React, { ReactNode, useEffect, useState } from "react";
import "./navbar.scss";
import Container from "../container/container";
import Grid from "../grid/grid";
import Row from "../grid/row";
import Col from "../grid/col";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { select_lang } from "../../redux/callbacksState/callbacksState.selectors";
import { useLangAny } from "../../tools/useLang";
import { setLang } from "../../redux/callbacksState/callbacksState.actions";
import TopLink from "../top-link/top-link";
import { RoutePath } from "../../../route-paths";
import { useLocation } from "react-router-dom";

interface Props {
  isSubNavHidden?: boolean;
  children?: ReactNode;
}

const Navbar: React.FC<Props> = () => {
  const [isSubpage, setIsSubpage] = useState(false);
  const lang = useSelector(select_lang, shallowEqual);
  const dispatch = useDispatch();
  const location = useLocation();
  const checkPage = () => {
    if (location.pathname === "/") setIsSubpage(false);
    else setIsSubpage(true);
  };

  useEffect(() => {
    checkPage();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // dispatch(setSectionScrollIndex(-1));
    checkPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <div className="navbar">
      <Container>
        <Grid>
          <Row>
            <Col size={[8, 8, 5, 3]}>
              <TopLink to={RoutePath.HOME}>
                {isSubpage ? (
                  <>
                    {"< "}
                    <span className="underline">{`${lang === "en" ? "Back to main" : "Wróć to głównej"}`}</span>
                  </>
                ) : (
                  <>
                    <span className="hideOnMobile">Szymon P. Nowak Mieszkiełło</span>
                    <span className="showOnMobile">SPNM</span>
                  </>
                )}
              </TopLink>
            </Col>
            <Col size={[2, 2, 4, 6]} className="toRight">
              <a href="mailto:me@spnm.pl" className="underline">
                {useLangAny(<>Currently&nbsp;at&nbsp;Cięty&nbsp;język</>, <>Obecnie&nbsp;w&nbsp;Cięty&nbsp;język</>)}
              </a>
            </Col>
            <Col size={[2, 2, 3, 3]} className="toRight langSwitch">
              <span className={`${lang === "pl" ? "underline" : ""}`} onClick={() => dispatch(setLang("pl"))}>
                PL
              </span>
              /
              <span className={`${lang === "en" ? "underline" : ""}`} onClick={() => dispatch(setLang("en"))}>
                ENG
              </span>
            </Col>
          </Row>
        </Grid>
      </Container>
    </div>
  );
};

export default Navbar;
