import React from "react";
import HeroSection, { HeroInfoColDto } from "./sections/hero-section/hero-section";
import useLang, { useLangAny } from "../../tools/useLang";
import ImageSection from "./sections/image-section/image-section";

const images = [
  "/images/portfolio/umi_hotel/UMI_Hotel_identyfikacja_wizualna_1.jpg",
  "/images/portfolio/umi_hotel/UMI_Hotel_identyfikacja_wizualna_2.jpg",
  "/images/portfolio/umi_hotel/UMI_Hotel_identyfikacja_wizualna_3.jpg",
  "/images/portfolio/umi_hotel/UMI_Hotel_identyfikacja_wizualna_4.jpg",
  "/images/portfolio/umi_hotel/UMI_Hotel_identyfikacja_wizualna_5.jpg",
  "/images/portfolio/umi_hotel/UMI_Hotel_identyfikacja_wizualna_6.jpg",
  "/images/portfolio/umi_hotel/UMI_Hotel_identyfikacja_wizualna_7.jpg",
  "/images/portfolio/umi_hotel/UMI_Hotel_identyfikacja_wizualna_8.jpg",
  "/images/portfolio/umi_hotel/UMI_Hotel_identyfikacja_wizualna_9.jpg",
  "/images/portfolio/umi_hotel/UMI_Hotel_identyfikacja_wizualna_10.jpg",
  "/images/portfolio/umi_hotel/UMI_Hotel_identyfikacja_wizualna_11.jpg",
  "/images/portfolio/umi_hotel/UMI_Hotel_identyfikacja_wizualna_12.jpg",
  "/images/portfolio/umi_hotel/UMI_Hotel_identyfikacja_wizualna_13.jpg",
  "/images/portfolio/umi_hotel/UMI_Hotel_identyfikacja_wizualna_14.jpg",
  "/images/portfolio/umi_hotel/UMI_Hotel_identyfikacja_wizualna_15.jpg",
  "/images/portfolio/umi_hotel/UMI_Hotel_identyfikacja_wizualna_16.jpg",
  "/images/portfolio/umi_hotel/UMI_Hotel_identyfikacja_wizualna_17.jpg",
  "/images/portfolio/umi_hotel/UMI_Hotel_identyfikacja_wizualna_18.jpg",
  "/images/portfolio/umi_hotel/UMI_Hotel_identyfikacja_wizualna_19.jpg",
  "/images/portfolio/umi_hotel/UMI_Hotel_identyfikacja_wizualna_20.jpg",
  "/images/portfolio/umi_hotel/UMI_Hotel_identyfikacja_wizualna_21.jpg",
  "/images/portfolio/umi_hotel/UMI_Hotel_identyfikacja_wizualna_22.jpg"
]

interface Props {
  pageIndex: number;
}

const UmiHotelPage: React.FC<Props> = (props: Props) => {
  // const portfolioItem = PortfolioData.find(x=>x.url === RoutePath.PORTFOLIO_UMIHOTEL)
  return (
    <>
      <HeroSection
        pageIndex={props.pageIndex}
        sectionIndex={1}
        title={useLang("Visual identity for a seaside hotel.", "Identyfikacja wizualna dla nadmorskiego hotelu.")}
        infoCols={[
          {
            header: useLang("Areas", "Zakres"),
            items: useLangAny(["Visual system", "Print"], ["System wizualny", "Print"])
          },
          {
            header: useLang("Role", "Rola"),
            items: useLangAny(["Creative designer"], ["Główny projektant"])
          },
          {
            header: useLang("For", "Dla"),
            items: useLangAny([<a target="_blank" rel="noopener noreferrer" href="http://connectthedots.pl/">connect the dots</a>, "Grupa Horeca"], [<a target="_blank" rel="noopener noreferrer" href="http://connectthedots.pl/">connect the dots</a>, "Grupa Horeca"])
          },
          {
            header: useLang("Year", "Rok"),
            items: ["2022"]
          }
        ] as Array<HeroInfoColDto>}
      />
      {images.map((imageUrl: string, index: number) => {
        return (
          <ImageSection pageIndex={props.pageIndex} sectionIndex={index + 3} size={9}>
            <img src={imageUrl} alt="" />
          </ImageSection>
        );
      })}
    </>
  )
}

export default UmiHotelPage;