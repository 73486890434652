import React from "react";
import Container from "../../../../components/container/container";
import Grid from "../../../../components/grid/grid";
import "./image-section.scss";
import { shallowEqual, useSelector } from "react-redux";
import DetectableSection from "../../../../components/detectable-section/detectable-section";
import { select_sectionScroll_index } from "../../../../redux/callbacksState/callbacksState.selectors";
import Row from "../../../../components/grid/row";
import Col from "../../../../components/grid/col";

interface Props {
  sectionIndex: number;
  pageIndex: number;
  children: React.ReactNode;
  size: 6 | 9
}

const ImageSection: React.FC<Props> = (props: Props) => {
  const sectionScrollIndex = useSelector(select_sectionScroll_index, shallowEqual)

  return (
    <DetectableSection index={(props.pageIndex * 1000) + props.sectionIndex} className="image_section">
      <Container>
        <Grid>
          <Row className={`image-wrapper ${sectionScrollIndex >= ((props.pageIndex * 1000) + props.sectionIndex) ? "isOpen" : ""}`}>
            <Col size={[props.size, props.size, 12, 12]}>
              {props.children}
            </Col>
          </Row>
        </Grid>
      </Container>
    </DetectableSection>
  )
}

export default ImageSection;