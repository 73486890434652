import React from "react";
import HeroSection, { HeroInfoColDto } from "./sections/hero-section/hero-section";
import useLang, { useLangAny } from "../../tools/useLang";
import ImageSection from "./sections/image-section/image-section";

const images = [
  "/images/portfolio/rapala_travel/Rapala_Travel_Concierge_Identyfikacja_wizualna_0.jpg",
  "/images/portfolio/rapala_travel/Rapala_Travel_Concierge_Identyfikacja_wizualna_1.jpg",
  "/images/portfolio/rapala_travel/Rapala_Travel_Concierge_Identyfikacja_wizualna_2.jpg",
  "/images/portfolio/rapala_travel/Rapala_Travel_Concierge_Identyfikacja_wizualna_3.jpg",
  "/images/portfolio/rapala_travel/Rapala_Travel_Concierge_Identyfikacja_wizualna_4.jpg",
  "/images/portfolio/rapala_travel/Rapala_Travel_Concierge_Identyfikacja_wizualna_5.jpg",
  "/images/portfolio/rapala_travel/Rapala_Travel_Concierge_Identyfikacja_wizualna_6.jpg",
  "/images/portfolio/rapala_travel/Rapala_Travel_Concierge_Identyfikacja_wizualna_7.jpg",
]

interface Props {
  pageIndex: number;
}

const RapalaPage: React.FC<Props> = (props: Props) => {

  return (
    <>
      <HeroSection
        pageIndex={props.pageIndex}
        sectionIndex={1}
        title={useLang("Visual identity for a private travel agency.", "Identyfikacja wizualna dla prywatnego biura podróży.")}
        infoCols={[
          {
            header: useLang("Areas", "Zakres"),
            items: useLangAny(["Visual Identity", "Print Design"], ["Identyfikacja wizualna", "Projekty druków"])
          },
          {
            header: useLang("Role", "Rola"),
            items: useLangAny(["Creative designer", "Art Director"], ["Główny projektant", "Nadzór artystyczny"])
          },
          {
            header: useLang("For", "Dla"),
            items: ["Michał Grala", "Agnieszka Rapala", "Weronika Rapala"]
          },
          {
            header: useLang("Year", "Rok"),
            items: ["2020"]
          }
        ] as Array<HeroInfoColDto>}
      />

      {images.map((imageUrl: string, index: number) => {
        return (
          <ImageSection pageIndex={props.pageIndex} sectionIndex={index + 3} size={9}>
            <img src={imageUrl} alt="" />
          </ImageSection>
        );
      })}
    </>
  )
}

export default RapalaPage;