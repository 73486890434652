


import React from "react";
import Container from "../container/container";
import Col from "../grid/col";
import Grid from "../grid/grid";
import Row from "../grid/row";
import "./fluent-grid.scss";

interface Props {
  children: React.ReactNode;
}

const FluentGrid: React.FC<Props> = (props: Props) => {
  const { children } = props;
  return (
    <div className={`fluent-grid`}>
      <div className={`fluent-grid-bg`}>
        <Container>
          <Grid>
            <Row>
              <Col size={[2, 2, 0, 0]}></Col>
              <Col size={[2, 2, 0, 0]}></Col>
              <Col size={[2, 2, 3, 3]}></Col>
              <Col size={[2, 2, 3, 3]}></Col>
              <Col size={[2, 2, 3, 3]}></Col>
              <Col size={[2, 2, 3, 3]}></Col>
            </Row>
          </Grid>
        </Container>
      </div>
      <div className="fluent-grid-content">
        {children}
      </div>
    </div>
  )
}

export default FluentGrid;
