import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./framework/redux/store";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "core-js/features/promise";
import { BrowserRouter } from "react-router-dom";

// import ReactGA from 'react-ga'

// if(process.env.NODE_ENV === 'production'){
//   ReactGA.initialize('UA-143705223-2');
// }

// <!-- Global site tag (gtag.js) - Google Analytics -->
// <script async src="https://www.googletagmanager.com/gtag/js?id=UA-143705223-2"></script>
// <script>
//   window.dataLayer = window.dataLayer || [];
//   function gtag() { dataLayer.push(arguments); }
//   gtag('js', new Date());

//   gtag('config', 'UA-143705223-2');
// </script>
// Create a root

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>
);


serviceWorker.unregister();