import React from "react";
import HeroSection, { HeroInfoColDto } from "./sections/hero-section/hero-section";
import useLang, { useLangAny } from "../../tools/useLang";
import ImageSection from "./sections/image-section/image-section";

const images = [
  "/images/portfolio/priv_lab/Private_Label_by_Selena_identyfikacja_wizualna_1.jpg",
  "/images/portfolio/priv_lab/Private_Label_by_Selena_identyfikacja_wizualna_2.jpg",
  "/images/portfolio/priv_lab/Private_Label_by_Selena_identyfikacja_wizualna_3.mp4",
  "/images/portfolio/priv_lab/Private_Label_by_Selena_identyfikacja_wizualna_4.jpg",
  "/images/portfolio/priv_lab/Private_Label_by_Selena_identyfikacja_wizualna_5.jpg",
  "/images/portfolio/priv_lab/Private_Label_by_Selena_identyfikacja_wizualna_6.jpg",
  "/images/portfolio/priv_lab/Private_Label_by_Selena_identyfikacja_wizualna_7.jpg",
  "/images/portfolio/priv_lab/Private_Label_by_Selena_identyfikacja_wizualna_8.jpg",
  "/images/portfolio/priv_lab/Private_Label_by_Selena_identyfikacja_wizualna_9.jpg"
]
interface Props {
  pageIndex: number;
}

const PrivLabsPage: React.FC<Props> = (props: Props) => {
  return (
    <>
      <HeroSection
        pageIndex={props.pageIndex}
        sectionIndex={1}
        title={useLang("Visual identity for white label chemical company.", "Identyfikacja wizualna dla producenta chemii przemysłowej.")}
        infoCols={
          [
            {
              header: useLang("Areas", "Zakres"),
              items: useLangAny(["Visual system", "Web Design"], ["System wizualny", "Strona internetowa"]),
            },
            {
              header: useLang("Role", "Rola"),
              items: useLangAny(["Creative designer"], ["Główny projektant"]),
            },
            {
              header: useLang("For", "Dla"),
              items: useLangAny([<a target="_blank" rel="noopener noreferrer" href="http://connectthedots.pl/">connect the dots</a>], [<a target="_blank" rel="noopener noreferrer" href="http://connectthedots.pl/">connect the dots</a>])
            },
            {
              header: useLang("Year", "Rok"),
              items: ["2021"],
            },
          ] as Array<HeroInfoColDto>
        }
      />

      {images.map((imageUrl: string, index: number) => {
        return (
          <ImageSection pageIndex={props.pageIndex} sectionIndex={index + 3} size={9}>
            {imageUrl.indexOf(".mp4") !== -1 ? <video src={imageUrl} autoPlay={true} controls={true}></video> : <img src={imageUrl} alt="" />}
          </ImageSection>
        );
      })}
    </>
  );
};

export default PrivLabsPage;
