import React from "react";
import HeroSection, { HeroInfoColDto } from "./sections/hero-section/hero-section";
import useLang, { useLangAny } from "../../tools/useLang";

import ImageSection from "./sections/image-section/image-section";



const images = [
  "/images/portfolio/logofolio/Logotyp_Abakanowicz_3D_Experience.png",
  "/images/portfolio/logofolio/Logotyp_Cybulskiego10.png",
  "/images/portfolio/logofolio/Logotyp_PRB_Kreta24.png",
  "/images/portfolio/logofolio/Logotyp_PRB_Metal_Bud.png",
  "/images/portfolio/logofolio/Projekty_Logo_Logotypow_1.jpg",
  "/images/portfolio/logofolio/Projekty_Logo_Logotypow_2.jpg",
  "/images/portfolio/logofolio/Projekty_Logo_Logotypow_3.jpg",
  "/images/portfolio/logofolio/Projekty_Logo_Logotypow_4.jpg",
  "/images/portfolio/logofolio/Projekty_Logo_Logotypow_5.jpg",
  "/images/portfolio/logofolio/Projekty_Logo_Logotypow_6.jpg",
  "/images/portfolio/logofolio/Projekty_Logo_Logotypow_7.jpg",
  "/images/portfolio/logofolio/Projekty_Logo_Logotypow_8.jpg",
  "/images/portfolio/logofolio/Projekty_Logo_Logotypow_9.jpg",
  "/images/portfolio/logofolio/Projekty_Logo_Logotypow_10.jpg",
  "/images/portfolio/logofolio/Projekty_Logo_Logotypow_11.jpg",
  "/images/portfolio/logofolio/Projekty_Logo_Logotypow_12.jpg",
  "/images/portfolio/logofolio/Projekty_Logo_Logotypow_13.jpg",
  "/images/portfolio/logofolio/Projekty_Logo_Logotypow_14.jpg",
  "/images/portfolio/logofolio/Projekty_Logo_Logotypow_15.jpg",
  "/images/portfolio/logofolio/Projekty_Logo_Logotypow_16.jpg",
  "/images/portfolio/logofolio/Projekty_Logo_Logotypow_17.jpg",
  "/images/portfolio/logofolio/Projekty_Logo_Logotypow_18.jpg",
  "/images/portfolio/logofolio/Projekty_Logo_Logotypow_19.jpg",
  "/images/portfolio/logofolio/Projekty_Logo_Logotypow_20.jpg",
  "/images/portfolio/logofolio/Projekty_Logo_Logotypow_21.jpg",
  "/images/portfolio/logofolio/Projekty_Logo_Logotypow_22.jpg",
  "/images/portfolio/logofolio/Projekty_Logo_Logotypow_23.jpg",
  "/images/portfolio/logofolio/Projekty_Logo_Logotypow_24.jpg",
  "/images/portfolio/logofolio/Projekty_Logo_Logotypow_25.jpg",
  "/images/portfolio/logofolio/Projekty_Logo_Logotypow_26.jpg",
  "/images/portfolio/logofolio/Projekty_Logo_Logotypow_27.jpg",
  "/images/portfolio/logofolio/Projekty_Logo_Logotypow_28.jpg",
  "/images/portfolio/logofolio/Projekty_Logo_Logotypow_29.jpg",
  "/images/portfolio/logofolio/Projekty_Logo_Logotypow_30.jpg",
  "/images/portfolio/logofolio/Projekty_Logo_Logotypow_31.jpg"
]

interface Props {
  pageIndex: number;
}

const LogofolioPage: React.FC<Props> = (props: Props) => {
  return (
    <>
      <HeroSection
        pageIndex={props.pageIndex}
        sectionIndex={1}
        title={useLang("Logos and logotypes designed in recent years.", "Sygnety i logotypy zaprojektowane na przestrzeni ostatnich lat.")}
        infoCols={
          [
            {
              header: useLang("Areas", "Zakres"),
              items: useLangAny(["Visual Identity", "Logo Design", "Branding"], ["Identyfikacja wizualna", "Projektowanie logo", "Branding"]),
            },
            {
              header: useLang("Role", "Rola"),
              items: useLangAny(["Creative designer"], ["Główny projektant"]),
            },
            {
              header: useLang("For", "Dla"),
              items: useLangAny(["Różne"], ["Various"]),
            },
            {
              header: useLang("Year", "Rok"),
              items: ["2013-2022"],
            },
          ] as Array<HeroInfoColDto>
        }
      />
      {images.map((imageUrl: string, index: number) => {
        return (
          <ImageSection pageIndex={props.pageIndex} sectionIndex={index + 2} size={9}>
            <img src={imageUrl} alt="" />
          </ImageSection>
        );
      })}
    </>
  );
};

export default LogofolioPage;
