import React, { useState } from "react";
import Container from "../../../../components/container/container";
import Col from "../../../../components/grid/col";
import Grid from "../../../../components/grid/grid";
import Row from "../../../../components/grid/row";
import "./facts-section.scss";
import StandardBlock from "../../../../components/standard-block/standard-block";

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/swiper.min.css";
// import "swiper/modules/pagination/pagination.min.css";
// import "swiper/modules/navigation/navigation.min.css";
// import "swiper/modules/scrollbar/scrollbar.min.css";

import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

// import 'swiper/components/navigation/navigation.scss';
// import 'swiper/components/pagination/pagination.scss';
// import 'swiper/components/scrollbar/scrollbar.scss';
import DetectableSection from "../../../../components/detectable-section/detectable-section";
import TrailsText from "../../../../components/trails-text/trails-text";
import useLang from "../../../../tools/useLang";
import SimpleTrailText from "../../../../components/trails-text/simple-trail-text";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

interface Props {
  index: number;
}

const FactsSection: React.FC<Props> = (props: Props) => {
  const [currentIndicator, setCurrentIndicator] = useState(1);
  const [totalIndicator, setTotalIndicator] = useState(4);

  return (
    <DetectableSection index={props.index} className="facts_section">
      <Container>
        <Grid>
          <Row>
            <Col size={12}>
              <StandardBlock className={"titleWidthIndicator"}>
                <div className="sectionSubtitle">
                  <TrailsText text={useLang("TRUE FACTS OF TRUTH", "NAJPRAWDZIWSZE PRAWDY")} sectionIndex={props.index} />
                </div>
                <div className="sectionSubtitle swiper-pagination-fraction-customWrapper">
                  <SimpleTrailText text={`${currentIndicator} / ${totalIndicator}`} sectionIndex={props.index} />
                  {/* <span> {currentIndicator} / {totalIndicator}</span> */}
                </div>
              </StandardBlock>
              <StandardBlock>
                <Swiper
                  slidesPerView={1}
                  loop={true}
                  spaceBetween={32}
                  pagination={{
                    type: "fraction",
                    formatFractionCurrent: (number: number) => {
                      setCurrentIndicator(number);
                      return number;
                    },
                    formatFractionTotal: (number: number) => {
                      setTotalIndicator(number);
                      return number;
                    },
                    // // renderFraction: function (current, total) {
                    // //   return '<span class="' + "swiper-pagination-fraction" + '">' + current + " / " + total + '</span>';
                    // // }
                  }}
                  autoplay={{
                    delay: 10000,
                  }}
                >
                  <SwiperSlide>
                    <div className="title">
                      <TrailsText text={useLang("One proposition is good enough. If not, I’ll design a second one.", "Jedna propozycja wystarczy. Jeśli nie – zaprojektuję drugą.")} sectionIndex={props.index} />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="title">
                      <TrailsText text={useLang("Meetings are overrated. We will probably never see each other.", "Spotkania są przereklamowane. Prawdopodobnie nigdy się nie zobaczymy.")} sectionIndex={props.index} />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="title">
                      <TrailsText text={useLang("Logo is not a visual system itself, but only one of its many elements.", "Logo nie jest systemem wizualnym samym w sobie, a tylko jednym z jego wielu elementów.")} sectionIndex={props.index} />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="title">
                      <TrailsText text={useLang("Font size is just one of many factors determining legibility.", "Wielkość czcionki to tylko jeden z wielu czynników warunkujących czytelność.")} sectionIndex={props.index} />
                    </div>
                  </SwiperSlide>
                </Swiper>
              </StandardBlock>
            </Col>
          </Row>
        </Grid>
      </Container>
    </DetectableSection>
  );
};

export default FactsSection;
