import React from "react";
import HeroSection, { HeroInfoColDto } from "./sections/hero-section/hero-section";
import useLang, { useLangAny } from "../../tools/useLang";

import ImageSection from "./sections/image-section/image-section";

const images = [
  "/images/portfolio/kwiaty_kawy/Kwiaty_Kawy_Identyfikacja_Wizualna_1.jpg",
  "/images/portfolio/kwiaty_kawy/Kwiaty_Kawy_Identyfikacja_Wizualna_2.jpg",
  "/images/portfolio/kwiaty_kawy/Kwiaty_Kawy_Identyfikacja_Wizualna_3.jpg",
  "/images/portfolio/kwiaty_kawy/Kwiaty_Kawy_Identyfikacja_Wizualna_4.jpg",
  "/images/portfolio/kwiaty_kawy/Kwiaty_Kawy_Identyfikacja_Wizualna_5.jpg",
  "/images/portfolio/kwiaty_kawy/Kwiaty_Kawy_Identyfikacja_Wizualna_6.jpg",
  "/images/portfolio/kwiaty_kawy/Kwiaty_Kawy_Identyfikacja_Wizualna_7.jpg",
  "/images/portfolio/kwiaty_kawy/Kwiaty_Kawy_Identyfikacja_Wizualna_8.jpg",
  "/images/portfolio/kwiaty_kawy/Kwiaty_Kawy_Identyfikacja_Wizualna_9.jpg",
  "/images/portfolio/kwiaty_kawy/Kwiaty_Kawy_Identyfikacja_Wizualna_10.jpg",
  "/images/portfolio/kwiaty_kawy/Kwiaty_Kawy_Identyfikacja_Wizualna_11.jpg",
]

interface Props {
  pageIndex: number;
}

const CoffeePage: React.FC<Props> = (props: Props) => {

  return (
    <>
      <HeroSection
        pageIndex={props.pageIndex}
        sectionIndex={1}
        title={useLang("Visual identity for a florist cafeteria.", "Identyfikacja wizualna dla kwiaciarnio-kawiarni.")}
        infoCols={[
          {
            header: useLang("Areas", "Zakres"),
            items: useLangAny(["Visual Identity", "Print Design"], ["Identyfikacja wizualna", "Projekty druków"])
          },
          {
            header: useLang("Role", "Rola"),
            items: useLangAny(["Creative designer", "Art Director"], ["Główny projektant", "Nadzór artystyczny"])
          },
          {
            header: useLang("For", "Dla"),
            items: ["Lucyna Schumacher"]
          },
          {
            header: useLang("Year", "Rok"),
            items: ["2019"]
          }
        ] as Array<HeroInfoColDto>}
      />

      {images.map((imageUrl: string, index: number) => {
        return (
          <ImageSection pageIndex={props.pageIndex} sectionIndex={index + 3} size={9}>
            <img src={imageUrl} alt="" />
          </ImageSection>
        );
      })}
    </>
  )
}

export default CoffeePage;