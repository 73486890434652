import "./App.scss";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { RoutePath } from "./route-paths";
import HomePage from "./framework/pages/home-page/home-page";
import Navbar from "./framework/components/navbar/navbar";
import Footer from "./framework/components/footer/footer";
import FluentGrid from "./framework/components/fluent-grid/fluent-grid";
// import SmoothScrollbar from "./framework/components/smooth-scrollbar/smooth-scrollbar";
import FluentFollowBlock from "./framework/components/fluent-follow-block/fluent-follow-block";
import PortfolioImg from "./framework/pages/home-page/sections/portfolio-section/portfolio-img";
// import GrainFilter from "./framework/components/grain-filter/grain-filter";
import SlideOverlay from "./framework/components/slide-overlay/slide-overlay";
import ImagesLoader from "./framework/components/images-loader/images-loader";
import SmoothScrollbar from "./framework/components/smooth-scrollbar/smooth-scrollbar";
import { PortfolioData, PortfolioItemDto } from "./framework/pages/portfolio-page/_data";
import PortfolioLayout from "./framework/pages/portfolio-page/portfolio-layout";

interface Props {
  match: any;
  pageIndex: number;
}

const App: React.FC<Props> = () => {
  return (
    <>
      <SlideOverlay />
      <FluentFollowBlock>
        <PortfolioImg />
      </FluentFollowBlock>
      <FluentGrid>
        <SmoothScrollbar>
          <Navbar />
          <Routes>
            <Route path={RoutePath.HOME} element={<HomePage pageIndex={1} />} />
            {PortfolioData.map((x: PortfolioItemDto, index: number) => {
              // return <Route key={index} path={x.url} element={x.page({ pageIndex: 2 + index })} />;
              return <Route key={index} path={x.url} element={<PortfolioLayout key={index} pageIndex={2 + index} />} />;
            })}
            {/* <Route path={RoutePath.PORTFOLIO_CENTENNIALHALL} element={<CentennialHallPage pageIndex={2} />} />
            <Route path={RoutePath.PORTFOLIO_UMIHOTEL} element={<UmiHotelPage pageIndex={3} />} />
            <Route path={RoutePath.PORTFOLIO_IRMATIQ} element={<Irmatiq pageIndex={4} />} />
            <Route path={RoutePath.PORTFOLIO_PELLEAPORTER} element={<PelleAPorterPage pageIndex={5} />} />
            <Route path={RoutePath.PORTFOLIO_MIKOLAJSKALAW} element={<MikolajskaPage pageIndex={4} />} />
            <Route path={RoutePath.PORTFOLIO_SMOOF} element={<SmoofPage pageIndex={4} />} />
            <Route path={RoutePath.PORTFOLIO_KARDYNALANDKARDYNAL} element={<KardynalPage pageIndex={5} />} />
            <Route path={RoutePath.PORTFOLIO_RAPALATRAVELCONCIERGE} element={<RapalaPage pageIndex={6} />} />
            <Route path={RoutePath.PORTFOLIO_COFFEEFLOWERS} element={<CoffeePage pageIndex={7} />} />
            <Route path={RoutePath.PORTFOLIO_ORTHO} element={<OrthoPage pageIndex={8} />} />
            <Route path={RoutePath.PORTFOLIO_LOGOFOLIO} element={<LogofolioPage pageIndex={9} />} /> */}
          </Routes>
          <Footer />
        </SmoothScrollbar>
      </FluentGrid>
      <ImagesLoader />
      {/* <GrainFilter /> */}
      {/* <CookiePolicyPopup /> */}
    </>
  );
};

export default App;
