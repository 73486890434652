import React from "react";
import "./grid.scss";

interface Props {
  children?: React.ReactNode,
  size: 2 | 3 | 4 | 5 | 6 | 8 | 9 | 10 | 12 | 20 | Array<number>,
  className?: string,
  offset?: 0 | 1 | 2 | 4 | 7 | 8 | 9 | 10 | Array<number | undefined>,
  top?: number;
  onClick?: any;
  forwardedRef?: any;
}

const Col: React.FC<Props> = (props: Props) => {
  const { children, size, className, offset, top, onClick, forwardedRef } = props;

  const setSize = (): string => {
    return "col-" + (Array.isArray(size) ? size[0] : size).toString();
  }

  const setMdSize = (): string => {
    if (Array.isArray(size) && size.length >= 2) {
      return " col-md-" + (size[1]).toString();
    } else return "";
  }

  const setSmSize = (): string => {
    if (Array.isArray(size) && size.length >= 3) {
      return " col-sm-" + (size[2]).toString();
    } else return "";
  }

  const setOffset = (): string => {
    return offset ? " col-offset-" + (Array.isArray(offset) ? offset[0] : offset) : "";
  }

  const setMdOffset = (): string => {
    if (Array.isArray(offset) && offset.length >= 2 && offset[1] !== undefined) {
      return " col-md-offset-" + (offset[1]).toString();
    } else return "";
  }

  const setSmOffset = (): string => {
    if (Array.isArray(offset) && offset.length >= 3 && offset[2] !== undefined) {
      return " col-sm-offset-" + (offset[2]).toString();
    } else return "";
  }

  const setXsOffset = (): string => {
    if (Array.isArray(offset) && offset.length >= 4 && offset[3] !== undefined) {
      return " col-xs-offset-" + (offset[3]).toString();
    } else return "";
  }

  const setClassName = (): string => {
    return className ? " " + className : "";
  }

  return (
    <div ref={forwardedRef} className={`col ${setSize()}${setMdSize()}${setSmSize()}${setOffset()}${setMdOffset()}${setSmOffset()}${setXsOffset()}${setClassName()}`} style={{ top: top ? top : "" }} onClick={onClick}>
      {children ? children : null}
    </div>
  )
}

export default Col;