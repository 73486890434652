import React from "react";
import "./images-loader.scss";

import AImg from "./../../assets/a.jpg";
import BImg from "./../../assets/b.jpg";
import CImg from "./../../assets/c.jpg";
import DImg from "./../../assets/d.jpg";

const ImagesLoader: React.FC<any> = () => {
  return (
    <div className="images-loader">
      <img src={AImg} alt=""/>
      <img src={BImg} alt=""/>
      <img src={CImg} alt=""/>
      <img src={DImg} alt=""/>
    </div>
  )
}

export default ImagesLoader;