import React, { } from "react";
import Container from "../../../../components/container/container";
import Col from "../../../../components/grid/col";
import Grid from "../../../../components/grid/grid";
import Row from "../../../../components/grid/row";
import "./areas-section.scss";
import StandardBlock from "../../../../components/standard-block/standard-block";
import DetectableSection from "../../../../components/detectable-section/detectable-section";
import TrailsText from "../../../../components/trails-text/trails-text";
import useLang from "../../../../tools/useLang";

interface Props {
  index: number;
}

const AreasSection: React.FC<Props> = (props: Props) => {

  return (
    <DetectableSection index={props.index} className="areas_section">
      <Container>
        <Grid>
          <Row >
            <Col size={12}>
              <StandardBlock>
                <div className="sectionSubtitle">
                  <TrailsText
                    text={useLang("AREAS OF EXPERTISE", "OBSZARY DZIAŁALNOŚCI")}
                    sectionIndex={props.index}
                  />
                </div>
              </StandardBlock>
              <StandardBlock>
                <div className="title">
                  <TrailsText
                    text={useLang("Branding. Designing visual identity systems and guidelines, logotypes, user interfaces, websites, packaging and prints.", "Branding. Projektowanie systemów identyfikacji wizualnej, logotypów, interfejsów użytkownika, stron internetowych, opakowań i publikacji.")}
                    sectionIndex={props.index}
                  />
                </div>
              </StandardBlock>
            </Col>
          </Row>
        </Grid>
      </Container>
    </DetectableSection>
  )
}

export default AreasSection;