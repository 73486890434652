import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import Container from "../../../../components/container/container";
import DetectableSection from "../../../../components/detectable-section/detectable-section";
import Col from "../../../../components/grid/col";
import Grid from "../../../../components/grid/grid";
import Row from "../../../../components/grid/row";
import StandardBlock from "../../../../components/standard-block/standard-block";
import TrailsText from "../../../../components/trails-text/trails-text";
import { select_sectionScroll_index } from "../../../../redux/callbacksState/callbacksState.selectors";
import "./hero-section.scss";

interface Props {
  sectionIndex: number;
  pageIndex: number;
  // children: React.ReactNode;
  title: string;
  infoCols?: Array<HeroInfoColDto>
}

export interface HeroInfoColDto {
  header: string;
  items: Array<React.ReactNode>
}

const HeroSection: React.FC<Props> = (props: Props) => {
  const sectionScrollIndex = useSelector(select_sectionScroll_index, shallowEqual)

  return (
    <DetectableSection index={(props.pageIndex * 1000) + props.sectionIndex} isNotHideable={true} className="hero_section">
      <Container>
        <Grid>
          <Row>
            <Col size={[12]}>
              <StandardBlock>
                <div className="title">
                  <TrailsText
                    text={props.title}
                    sectionIndex={(props.pageIndex * 1000) + props.sectionIndex}
                  />
                </div>
              </StandardBlock>
            </Col>
          </Row>
          {props.infoCols ?
            <Row className={`info-cols-block ${sectionScrollIndex >= ((props.pageIndex * 1000) + props.sectionIndex) ? "isActive" : ""}`}>
              {props.infoCols.map((col: HeroInfoColDto, index: number) => {
                return (
                  <Col size={[2, 2, 6, 6]} key={index} className={`info-col info-col-delay-${index}`}>
                    <StandardBlock>
                      <p>
                        <strong>{col.header}</strong>
                      </p>
                      <br />
                      {col.items ? col.items.map((item: React.ReactNode, indexTwo: number) => {
                        return (
                          <p key={indexTwo}>
                            {item}
                          </p>
                        )
                      }) : null}
                    </StandardBlock>
                  </Col>
                )
              })}
            </Row>
            : null}
        </Grid>
      </Container>
    </DetectableSection>
  )
}

export default HeroSection;