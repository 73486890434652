import { createSelector } from 'reselect';
import { SPNMStore } from '../store.types';

const callbacksState = (state: SPNMStore) => state.callbacksState;

export const selectIs_globalToastFailedToFetchActive = createSelector(
  [callbacksState],
  callbacksState => {
    return callbacksState.is_globalToastFailedToFetchActive
  }
);

export const select_is_slideOverlayActive = createSelector(
  [callbacksState],
  callbacksState => {
    return callbacksState.is_slideOverlayActive
  }
);

export const select_activeOfferIndex = createSelector(
  [callbacksState],
  callbacksState => {
    return callbacksState.activeOfferIndex
  }
);

export const select_activeTeamMemberIndex = createSelector(
  [callbacksState],
  callbacksState => {
    return callbacksState.activeTeamMemberIndex
  }
);

export const select_scrollTopHandler = createSelector(
  [callbacksState],
  callbacksState => {
    return callbacksState.scrollTopHandler
  }
);

export const select_scrollTopHandler_value = createSelector(
  [callbacksState],
  callbacksState => {
    return callbacksState.scrollTopHandlerValue
  }
);

export const select_section_index = createSelector(
  [callbacksState],
  callbacksState => {
    return callbacksState.sectionIndex
  }
);

export const select_sectionScroll_index = createSelector(
  [callbacksState],
  callbacksState => {
    return callbacksState.sectionScrollIndex
  }
);

export const select_sectionCard_index = createSelector(
  [callbacksState],
  callbacksState => {
    return callbacksState.sectionCardIndex
  }
);

export const select_scrollY = createSelector(
  [callbacksState],
  callbacksState => {
    return callbacksState.scrollY
  }
);

export const select_currecntPortfolioBlockActive = createSelector(
  [callbacksState],
  callbacksState => {
    return callbacksState.currecntPortfolioBlockActive
  }
);

export const select_isPortfolioActive = createSelector(
  [callbacksState],
  callbacksState => {
    return callbacksState.isPortfolioActive
  }
);

export const select_lang = createSelector(
  [callbacksState],
  callbacksState => {
    return callbacksState.lang
  }
);