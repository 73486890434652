import { useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { setSectionScrollIndex } from "../redux/callbacksState/callbacksState.actions";
import { select_is_slideOverlayActive } from "../redux/callbacksState/callbacksState.selectors";

export default function useWindowScroll(expectedIndex: number, ref: any) {
  const slideOverlayState = useSelector(select_is_slideOverlayActive, shallowEqual);

  const dispatch = useDispatch();

  const handleScroll = () => {
    // if (ref && ref.current && ref.current.getBoundingClientRect().top < 440 && ref.current.getBoundingClientRect().top > 261 - ref.current!.getBoundingClientRect().height) {
    if (ref && ref.current && ref.current.getBoundingClientRect().top < window.innerHeight / 1.5 && ref.current.getBoundingClientRect().top > window.innerHeight / 1.5 - ref.current!.getBoundingClientRect().height) {
      // if (sectionScrollIndex !== expectedIndex) {
      dispatch(setSectionScrollIndex(expectedIndex));
      // }
    }
  }

  useEffect(() => {
    dispatch(setSectionScrollIndex(-1));
    var intervalObj = setInterval(() => {
      handleScroll();
    }, 1000);
    window.addEventListener("touchmove", handleScroll);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("touchmove", handleScroll);
      window.removeEventListener("scroll", handleScroll);
      clearInterval(intervalObj);
      dispatch(setSectionScrollIndex(-1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleScroll();
    setTimeout(() => {
      handleScroll();
    }, 300);
    setTimeout(() => {
      handleScroll();
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slideOverlayState])
}