/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useRef, useState } from "react";
import "./smooth-scrollbar.scss";
import useWindowSize from './../../tools/useWindowSize';
import { useDispatch } from "react-redux";
import { setScrollY } from "../../redux/callbacksState/callbacksState.actions";

interface Props {
  children: React.ReactNode;
}

export const skewConfig = {
  ease: 0.08,
  current: 0,
  previous: 0,
  rounded: 0
}

const SmoothScrollbar: React.FC<Props> = (props: Props) => {
  const scrollContainer = useRef<any>();
  const [isMobile, setIsMobile] = useState(false);
  const size = useWindowSize();
  const dispatch = useDispatch();

  useEffect(() => {
    heightCheck();
    requestAnimationFrame(() => skewScrolling());
    setInterval(() => {
      heightCheck();
    }, 1000);

    setTimeout(() => {
      heightCheck();
    }, 200);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    heightCheck();
  }, [size.height])

  const heightCheck = () => {
    document.body.style.height = `${scrollContainer.current.getBoundingClientRect().height}px`;
    if ((window as any).mobileAndTabletCheck()) {
      setIsMobile(true);
    }
    else {
      setIsMobile(false);
    }
  }

  const skewScrolling = () => {
    skewConfig.current = window.scrollY;
    skewConfig.previous += (skewConfig.current - skewConfig.previous) * (skewConfig.ease);
    skewConfig.rounded = Math.round(skewConfig.previous * 100) / 100;
    const difference = skewConfig.current - skewConfig.rounded;
    const acceleration = difference / size.width;
    const velocity = +acceleration;
    const skew = velocity * 4;
    scrollContainer.current.style.transform = `translate3d(0, -${skewConfig.rounded}px, 0) skewY(${skew}deg)`;

    dispatch(setScrollY(skewConfig.rounded));
    requestAnimationFrame(() => skewScrolling());
  }

  return (
    <>
      {
        isMobile ?
          <>
            <div className={'smooth-scrollbar-wrapper mobileMock'}>
              <div ref={scrollContainer} className={'smooth-scrollbar mobileMock'}>
                {props.children}
              </div>
            </div>
            <div className={'smooth-scrollbar-wrapper-mobile'}>
              <div className={'smooth-scrollbar-mobile'}>
                {props.children}
              </div>
            </div>
          </> :
          <div className={'smooth-scrollbar-wrapper'}>
            <div ref={scrollContainer} className={'smooth-scrollbar'}>
              {props.children}
            </div>
          </div>
      }
    </>
  )
}

export default SmoothScrollbar;