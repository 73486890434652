/* eslint-disable jsx-a11y/anchor-has-content */
import React, { } from "react";
import "./footer.scss";
import Col from "../grid/col";
import Grid from "../grid/grid";
import Row from "../grid/row";
import Container from "../container/container";
import { useSelector, shallowEqual } from "react-redux";
import { select_scrollTopHandler_value } from "../../redux/callbacksState/callbacksState.selectors";

interface Props {
}

const Footer: React.FC<Props> = () => {
  const scrollTopHandler_value = useSelector(select_scrollTopHandler_value, shallowEqual);

  return (
    <footer className={`${scrollTopHandler_value === 1 ? "hide" : "show"}`}>
      <Container>
        <Grid>
          <Row>
            <Col size={[10, 10, 6, 6]}>
              sp_nm
            </Col>
            <Col size={[2, 2, 6, 6]} className="onMobileToRight">
              © {new Date().getFullYear()}
            </Col>
          </Row>
        </Grid>
      </Container>
    </footer>
  )
}

export default Footer;