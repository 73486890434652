import React from "react";
import Container from "../../../../components/container/container";
import Col from "../../../../components/grid/col";
import Grid from "../../../../components/grid/grid";
import Row from "../../../../components/grid/row";
import "./portfolio-section.scss";
import StandardBlock from "../../../../components/standard-block/standard-block";
import PortfolioBlock from "./portfolio-block";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setIsPortfolioActive } from "../../../../redux/callbacksState/callbacksState.actions";
import DetectableSection from "../../../../components/detectable-section/detectable-section";
import TrailsText from "../../../../components/trails-text/trails-text";
import { select_lang, select_sectionScroll_index } from "../../../../redux/callbacksState/callbacksState.selectors";
import TopLink from "../../../../components/top-link/top-link";
import { PortfolioData, PortfolioItemDto } from "../../../portfolio-page/_data";

interface Props {
  index: number;
}

const PortfolioSection: React.FC<Props> = (props: Props) => {
  const sectionScrollIndex = useSelector(select_sectionScroll_index, shallowEqual);
  const lang = useSelector(select_lang, shallowEqual);

  const dispatch = useDispatch();

  const onMouseOver = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    dispatch(setIsPortfolioActive(true));
  };

  const onMouseOut = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    dispatch(setIsPortfolioActive(false));
  };

  return (
    <DetectableSection index={props.index} className="portfolio_section">
      <Container>
        <Grid>
          <Row className="noPadding">
            <Col size={12}>
              <StandardBlock>
                <div className="sectionSubtitle">
                  <TrailsText text={"PORTFOLIO"} sectionIndex={props.index} />
                </div>
              </StandardBlock>
            </Col>
          </Row>
          <div className={`portfolio-wrapper ${props.index <= sectionScrollIndex ? "isOpen" : ""}`} onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
            <hr />
            {PortfolioData.map((x: PortfolioItemDto, index: number) => {
              const num = x.order < 10 ? "00" + x.order : x.order < 100 ? "0" + x.order : x.order.toString();
              return (
                <TopLink to={x.url} key={index}>
                  <PortfolioBlock num={num} img={x.cover} isFirst={index === 0}>
                    {lang === "en" ? x.name_en : lang === "pl" ? x.name_pl : x.name_en}
                  </PortfolioBlock>
                </TopLink>
              );
            })}
          </div>
        </Grid>
      </Container>
    </DetectableSection>
  );
};

export default PortfolioSection;
