import React from "react";
import "./home-page.scss";
import Helmet from "react-helmet";
import PageWrapper from "../../components/page-wrapper/page-wrapper";
import AboutSection from "./sections/about-section/about-section";
import ContactSection from "./sections/contact-section/contact-section";
import AreasSection from "./sections/areas-section/areas-section";
import PortfolioSection from "./sections/portfolio-section/portfolio-section";
import FactsSection from "./sections/facts-section/facts-section";

const HomePage: React.FC<any> = () => {

  return (
    <PageWrapper className="home-page">
      <Helmet>
        <title>{'SPNM'}</title>
      </Helmet>
      <AboutSection index={1} />
      <AreasSection index={2} />
      <PortfolioSection index={3} />
      <FactsSection index={4} />
      <ContactSection index={5} />
    </PageWrapper>
  )
}

export default HomePage;