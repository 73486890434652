import React from "react";
import HeroSection, { HeroInfoColDto } from "./sections/hero-section/hero-section";
import useLang, { useLangAny } from "../../tools/useLang";

import ImageSection from "./sections/image-section/image-section";

const images = [
  "/images/portfolio/pelle_a_porter/pelle_a_porter_identyfikacja_wizualna_0.jpg",
  "/images/portfolio/pelle_a_porter/pelle_a_porter_identyfikacja_wizualna_1.jpg",
  "/images/portfolio/pelle_a_porter/pelle_a_porter_identyfikacja_wizualna_2.jpg",
  "/images/portfolio/pelle_a_porter/pelle_a_porter_identyfikacja_wizualna_3.jpg",
  "/images/portfolio/pelle_a_porter/pelle_a_porter_identyfikacja_wizualna_4.jpg",
  "/images/portfolio/pelle_a_porter/pelle_a_porter_identyfikacja_wizualna_5.jpg",
  "/images/portfolio/pelle_a_porter/pelle_a_porter_identyfikacja_wizualna_6.jpg",
  "/images/portfolio/pelle_a_porter/pelle_a_porter_identyfikacja_wizualna_7.jpg",
  "/images/portfolio/pelle_a_porter/pelle_a_porter_identyfikacja_wizualna_8.jpg",
  "/images/portfolio/pelle_a_porter/pelle_a_porter_identyfikacja_wizualna_9.jpg",
  "/images/portfolio/pelle_a_porter/pelle_a_porter_identyfikacja_wizualna_10.jpg",
  "/images/portfolio/pelle_a_porter/pelle_a_porter_identyfikacja_wizualna_11.jpg"
]

interface Props {
  pageIndex: number;
}

const PelleAPorterPage: React.FC<Props> = (props: Props) => {

  return (
    <>
      <HeroSection
        pageIndex={props.pageIndex}
        sectionIndex={1}
        title={useLang("Visual identity for a natural leather producer.", "Identyfikacja wizualna dla producenta skór naturalnych.")}
        infoCols={[
          {
            header: useLang("Areas", "Zakres"),
            items: useLangAny(["Visual Identity", "Print design"], ["Język wizualny", "Projekty druków"])
          },
          {
            header: useLang("Role", "Rola"),
            items: useLangAny(["Creative designer"], ["Główny projektant"])
          },
          {
            header: useLang("For", "Dla"),
            items: useLangAny([<a target="_blank" rel="noopener noreferrer" href="http://connectthedots.pl/">connectthedots agency</a>], [<a target="_blank" rel="noopener noreferrer" href="http://connectthedots.pl/">Agencja connectthedots</a>])
          },
          {
            header: useLang("Year", "Rok"),
            items: ["2018-2020"]
          }
        ] as Array<HeroInfoColDto>}
      />
      {images.map((imageUrl: string, index: number) => {
        return (
          <ImageSection pageIndex={props.pageIndex} sectionIndex={index + 3} size={9}>
            <img src={imageUrl} alt="" />
          </ImageSection>
        );
      })}

    </>
  )
}

export default PelleAPorterPage;