import React from "react";
import Container from "../../../../components/container/container";
import DetectableSection from "../../../../components/detectable-section/detectable-section";
import Col from "../../../../components/grid/col";
import Grid from "../../../../components/grid/grid";
import Row from "../../../../components/grid/row";
import StandardBlock from "../../../../components/standard-block/standard-block";
import TrailsText from "../../../../components/trails-text/trails-text";
import useLang from "../../../../tools/useLang";
import "./contact-section.scss";

interface Props {
  index: number;
}

const ContactSection: React.FC<Props> = (props: Props) => {

  return (
    <DetectableSection index={props.index} className="contact-section">
      <Container>
        <Grid>
          <Row >
            <Col size={[8, 8, 12, 12]}>
              <StandardBlock>
                <div className="sectionSubtitle">
                  <TrailsText
                    text={useLang("CONTACT", "KONTAKT")}
                    sectionIndex={props.index}
                  />
                </div>
              </StandardBlock>
              <StandardBlock>
                <div className="title regular">
                  <TrailsText
                    text={useLang("Drop me a line:", "Napisz do mnie:")}
                    sectionIndex={props.index}
                  />
                  <a href="mailto:me@spnm.pl" className="underline shortTrail">
                    <TrailsText
                      addictionalDelay={1}
                      text={"me@spnm.pl"}
                      sectionIndex={props.index}
                    />
                  </a>
                </div>
              </StandardBlock>
            </Col>
            <Col size={[4, 4, 12, 12]}>
              <StandardBlock>
                <div className="sectionSubtitle subtiteWithGap">
                  <TrailsText
                    text={useLang("SOCIAL", "SOCJAL")}
                    sectionIndex={props.index}
                  />
                </div>
              </StandardBlock>
              <StandardBlock>
                <div className="title hover_underline externalLinks">
                  <a target="_blank" rel="noopener noreferrer" href="https://www.behance.net/sp_nm" className="hover_underline">
                    <TrailsText
                      text={"Behance"}
                      sectionIndex={props.index}
                    />
                  </a>
                  <a target="_blank" rel="noopener noreferrer" href="https://open.spotify.com/user/11125064471?si=ZHsp67EjQZG--ZAsTQ-1Gg" className="hover_underline">
                    <TrailsText
                      addictionalDelay={1}
                      text={"Spotify"}
                      sectionIndex={props.index}
                    />
                  </a>
                </div>
              </StandardBlock>
            </Col>
          </Row>
          <Row  className="titleGap">
            <Col size={12}>
              <StandardBlock>
                <div className="title">
                  <br />
                  <TrailsText
                    addictionalDelay={2}
                    text={useLang("Developed by", "Zaprogramował")}
                    sectionIndex={props.index}
                  />
                  <a href="mailto:michal.p.kowalczyk@gmail.com" className="underline shortTrail">
                    <TrailsText
                      addictionalDelay={3}
                      text={"Michał\u00A0P.\u00A0Kowalczyk"}
                      sectionIndex={props.index}
                    />
                  </a>
                </div>
              </StandardBlock>
            </Col>
          </Row>
          <br />
        </Grid>
      </Container>
    </DetectableSection>
  )
}

export default ContactSection;