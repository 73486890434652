const CallbacksStateTypes = {
  TOAST_FAILEDTOFETCH_ACTIVATED: 'TOAST_FAILEDTOFETCH_ACTIVATED',
  HIDE_SLIDE_OVERLAY_SUCCESS: 'HIDE_SLIDE_OVERLAY_SUCCESS',
  SHOW_SLIDE_OVERLAY_SUCCESS: 'SHOW_SLIDE_OVERLAY_SUCCESS',
  SET_ACTIVE_OFFERINDEX: 'SET_ACTIVE_OFFERINDEX',
  SET_ACTIVE_TEAMMEMBERINDEX: 'SET_ACTIVE_TEAMMEMBERINDEX',
  SET_SCROLLTOP_HANDLER: 'SET_SCROLLTOP_HANDLER',
  SET_SCROLLTOP_HANDLER_VALUE: 'SET_SCROLLTOP_HANDLER_VALUE',
  SET_SECTION_INDEX: 'SET_SECTION_INDEX',
  SET_SECTIONSCROLL_INDEX: 'SET_SECTIONSCROLL_INDEX',
  SET_SECTIONCARD_INDEX: 'SET_SECTIONCARD_INDEX',
  SET_SCROLLY: 'SET_SCROLLY',
  SET_ISPORTFOLIOACTIVE: 'SET_ISPORTFOLIOACTIVE',
  SET_CURRECNTPORTFOLIOBLOCKACTIVE: 'SET_CURRECNTPORTFOLIOBLOCKACTIVE',
  SET_LANG: 'SET_LANG',
};

export default CallbacksStateTypes;