import { RoutePath } from "../../../route-paths";
import BibiPage from "./bibi-page";
import CentennialHallPage from "./centennial-hall-page";
import CoffeePage from "./coffee-page";
import Irmatiq from "./irmatiq";
import LdafPage from "./ldaf-page";
import LogofolioPage from "./logofolio-page";
import NomadHouse from "./nomad-house";
import PelleAPorterPage from "./pelleAPorter-page";
import PrivLabsPage from "./priv-labs-page";
import RapalaPage from "./rapala-page";
import SmoofPage from "./smoof-page";
import UmiHotelPage from "./umi-hotel";

export interface PortfolioItemDto {
  order: number,
  page: React.FC<any>,
  name_en: string,
  name_pl: string,
  cover: string,
  url: string
}

export const PortfolioData: Array<PortfolioItemDto> = [
  {
    order: 1,
    page: CentennialHallPage,
    name_en: "Centennial Hall",
    name_pl: "Hala Stulecia",
    cover: "/images/portfolio/hala/cover.jpg",
    url: RoutePath.PORTFOLIO_CENTENNIALHALL,
  },
  {
    order: 2,
    page: UmiHotelPage,
    name_en: "UMI Hotel",
    name_pl: "UMI Hotel",
    cover: "/images/portfolio/umi_hotel/cover.jpg",
    url: RoutePath.PORTFOLIO_UMIHOTEL
  },
  {
    order: 3,
    page: Irmatiq,
    name_en: "Irmatiq",
    name_pl: "Irmatiq",
    cover: "/images/portfolio/irmatiq/cover.jpg",
    url: RoutePath.PORTFOLIO_IRMATIQ
  },
  {
    order: 4,
    page: PelleAPorterPage,
    name_en: "pelle–á–porter",
    name_pl: "pelle–á–porter",
    cover: "/images/portfolio/pelle_a_porter/cover.jpg",
    url: RoutePath.PORTFOLIO_PELLEAPORTER
  },
  {
    order: 5,
    page: NomadHouse,
    name_en: "Nomad House",
    name_pl: "Nomad House",
    cover: "/images/portfolio/nomad/cover.jpg",
    url: RoutePath.PORTFOLIO_NOMADHOUSE
  },
  {
    order: 6,
    page: BibiPage,
    name_en: "Bibi Nail Salon",
    name_pl: "Bibi Salon Paznokci",
    cover: "/images/portfolio/bibi/cover.jpg",
    url: RoutePath.PORTFOLIO_BIBI
  },
  {
    order: 7,
    page: PrivLabsPage,
    name_en: "Private Labels",
    name_pl: "Private Labels",
    cover: "/images/portfolio/priv_lab/cover.jpg",
    url: RoutePath.PORTFOLIO_PRIV_LAB
  },
  {
    order: 8,
    page: LdafPage,
    name_en: "La Dame Aux Fleurs",
    name_pl: "La Dame Aux Fleurs",
    cover: "/images/portfolio/ldaf/cover.jpg",
    url: RoutePath.PORTFOLIO_LDAF
  },
  {
    order: 9,
    page: RapalaPage,
    name_en: "Rapala Travel Concierge",
    name_pl: "Rapala Travel Concierge",
    cover: "/images/portfolio/rapala_travel/cover.jpg",
    url: RoutePath.PORTFOLIO_RAPALATRAVELCONCIERGE
  },
  {
    order: 10,
    page: CoffeePage,
    name_en: "Coffee Flowers",
    name_pl: "Kwiaty Kawy",
    cover: "/images/portfolio/kwiaty_kawy/cover.jpg",
    url: RoutePath.PORTFOLIO_COFFEEFLOWERS
  },
  {
    order: 11,
    page: SmoofPage,
    name_en: "SMOOF",
    name_pl: "SMOOF",
    cover: "/images/portfolio/smoof/cover.jpg",
    url: RoutePath.PORTFOLIO_SMOOF
  },
  {
    order: 12,
    page: LogofolioPage,
    name_en: "Logofolio",
    name_pl: "Logofolio",
    cover: "/images/portfolio/logofolio/cover.jpg",
    url: RoutePath.PORTFOLIO_LOGOFOLIO
  },
];


// "hala"
// "umi_hotel"
// "irmatiq"
// "pelle_a_porter"
// "nomad"
// "bibi"
// "priv_lab"

// "ldaf"
// "rapala_travel"
// "kwiaty_kawy"
// "smoof"
// "logofolio"


// "kardynal"
// "mikolajska"
// "ortho"