import React, { } from "react";
import Container from "../../../../components/container/container";
import Col from "../../../../components/grid/col";
import Grid from "../../../../components/grid/grid";
import Row from "../../../../components/grid/row";
import "./description-section.scss";
import StandardBlock from "../../../../components/standard-block/standard-block";
import DetectableSection from "../../../../components/detectable-section/detectable-section";
import SimpleTrailText from "../../../../components/trails-text/simple-trail-text";

interface Props {
  sectionIndex: number;
  pageIndex: number;
  description: string;
}

const DescriptionSection: React.FC<Props> = (props: Props) => {

  return (
    <DetectableSection index={(props.pageIndex * 1000) + props.sectionIndex} className="description_section">
      <Container>
        <Grid>
          <Row >
            <Col size={[10, 10, 12, 12]} offset={[2, 2, 0, 0]}>
              <StandardBlock>
                <div className="description">
                  <SimpleTrailText
                    text={props.description}
                    sectionIndex={(props.pageIndex * 1000) + props.sectionIndex}
                  />
                  {/* <TrailsText
                    text={props.description}
                    sectionIndex={(props.pageIndex * 1000) + props.sectionIndex}
                  /> */}
                </div>
              </StandardBlock>
            </Col>
          </Row>
        </Grid>
      </Container>
    </DetectableSection>
  )
}

export default DescriptionSection;