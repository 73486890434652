import React, { useEffect } from "react";
import Col from "../../../../components/grid/col";
import Row from "../../../../components/grid/row";
import "./portfolio-section.scss";
import StandardBlock from "../../../../components/standard-block/standard-block";
import { useDispatch } from "react-redux";
import { setCurrecntPortfolioBlockActive, setIsPortfolioActive } from "../../../../redux/callbacksState/callbacksState.actions";
interface Props {
  children: React.ReactNode,
  num: string,
  img: string,
  isFirst?: boolean
}

const PortfolioBlock: React.FC<Props> = (props: Props) => {

  const { num, children, img } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.isFirst) {
      dispatch(setCurrecntPortfolioBlockActive(img));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onMouseOver = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    dispatch(setCurrecntPortfolioBlockActive(img));
    dispatch(setIsPortfolioActive(true));
  }

  const onMouseOut = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    // dispatch(setCurrecntPortfolioBlockActive(""));
  }

  return (
    <>
      <div onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
        <Row className="portfolio-block">
          <Col size={2} className="hideOnMobile">
            <StandardBlock>
              <div className="subtitle">{num}</div>
            </StandardBlock>
          </Col>
          <Col size={10}>
            <StandardBlock>
              <div className="title">{children}</div>
            </StandardBlock>
          </Col>
        </Row>
      </div>
      <hr />
    </>
  )
}

export default PortfolioBlock;