import React from "react";
import "./standard-block.scss";

interface Props {
  children: React.ReactNode,
  variant?: "dark" | "white",
  align?: "left" | "center" | "right",
  className?: string
}

const StandardBlock: React.FC<Props> = (props: Props) => {
  const { children, variant, align, className } = props;

  return (
    <div className={`tk-franklin-gothic-urw standard-block ${variant ? variant : "dark"} ${align ? align : "left"}${className ? " " + className : ""}`}>
      {children}
    </div>
  )
}

export default StandardBlock;
